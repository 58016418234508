export const getTimeFromDate = (date: Date | string | number) => {
  if (date instanceof Date) {
    return date.getTime();
  }

  if (typeof date === "number") {
    return date;
  }

  try {
    return new Date(date).getTime();
  } catch (error) {
    console.error(error);
    return Date.now();
  }
};
