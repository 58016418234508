import { CurrencyCode } from "@smartrr/shared/currencyCode";
import { BigIntString } from "@smartrr/shared/entities/BigIntString";
import { ISmartrrOrderStatus } from "@smartrr/shared/entities/Order";
import { formatMoney } from "@smartrr/shared/utils/formatMoney";

export const getOrderTotal = (
  shopIncome: BigIntString,
  totalRefund: BigIntString,
  orderStatus: `${ISmartrrOrderStatus}` | ISmartrrOrderStatus,
  orgCurrency: CurrencyCode | undefined = "USD",
  refundIncluded = true
) => {
  if (
    !isNaN(shopIncome) &&
    !isNaN(totalRefund) &&
    (orderStatus !== "cancelled" || (orderStatus == "cancelled" && totalRefund == 0))
  ) {
    if (!refundIncluded) {
      return formatMoney(BigIntString.fromBigIntString(shopIncome - totalRefund), orgCurrency);
    }
    return formatMoney(BigIntString.fromBigIntString(shopIncome), orgCurrency);
  }
  return formatMoney(BigIntString.fromBigIntString(0), orgCurrency || "USD");
};
