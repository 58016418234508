export const getSelectedDatesFromFilter = (filter: string | string[] | undefined) => {
  if (!filter) {
    return;
  }

  let start, end;

  if (Array.isArray(filter)) {
    [start, end] = filter;
    return { start: new Date(+start), end: new Date(+end) };
  }

  return { start: new Date(+filter), end: new Date(+filter) };
};
